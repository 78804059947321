import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"
import { VideoPlayer } from "src/components/video-player"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      customers_title: string
      customers_copy: string
      discovery_title: string
      discovery_copy: string
      buyers_copy: string
      search_title: string
      search_copy: string
      selling_title: string
      selling_copy: string
      casual_sellers_copy: string
      profile_title: string
      profile_copy: string
      blueprint_title: string
      blueprint_copy: string
      expertise: string
    }
    images: {
      hero_static: any
      grid_1_value_static: any
      grid_1_buyer_static: any
      grid_1_item_static: any
      grid_1_ebay_static: any
      browse_static: any
      grid_2_framework_static: any
      grid_2_mobile_static: any
      search_static: any
      selling_static: any
      grid_3_mobile_static: any
      grid_3_framework_static: any
      profile_static: any
      sizzle_static: any
      
    }
    videos: {
      hero: any
      grid_1_value: any
      grid_1_buyer: any
      browse: any
      grid_2_mobile: any
      search: any
      selling: any
      grid_3_mobile: any
      profile: any
      sizzle: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()
  

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive 
            video={videos.hero} 
            image={images.hero_static} 
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.customers_title}</Headline>
          <Paragraph>{copy.customers_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp0: "$1", bp1: "$2" }}>
            <Col>
              <VideoLoopResponsive
                ratio="$1/1"
                video={videos.grid_1_value}
                image={images.grid_1_value_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$1/1"
                video={videos.grid_1_buyer}
                image={images.grid_1_buyer_static}
              />
            </Col>
            <Col 
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_1_item_static} />
            </Col>
            <Col 
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_1_ebay_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.discovery_title}</Headline>
          <Paragraph>{copy.discovery_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.browse}
            image={images.browse_static}
            ratio="$16/9"
          />
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.buyers_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col>
              <ImgFluid image={images.grid_2_framework_static} />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$3/4"
                video={videos.grid_2_mobile}
                image={images.grid_2_mobile_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>
      
      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.search_title}</Headline>
          <Paragraph>{copy.search_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.search}
            image={images.search_static}
            ratio="$16/9"
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.selling_title}</Headline>
          <Paragraph>{copy.selling_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.selling}
            image={images.selling_static}
            ratio="$16/9"
          />
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.casual_sellers_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col>
              <VideoLoopResponsive
                ratio="$3/4"
                video={videos.grid_3_mobile}
                image={images.grid_3_mobile_static}
              />
            </Col>
            <Col>
              <ImgFluid image={images.grid_3_framework_static} />
            </Col> 
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.profile_title}</Headline>
          <Paragraph>{copy.profile_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.profile}
            image={images.profile_static}
            ratio="$16/9"
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <HeadlineAndParagraph>
                <Headline>{copy.blueprint_title}</Headline>
                <Paragraph>{copy.blueprint_copy}</Paragraph>
            </HeadlineAndParagraph>
          </Col>
        </Grid>  
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            ratio="$16/9"
            video={videos.sizzle}
            image={images.sizzle_static}
          />
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
      </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "ebay" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        customers_title
        customers_copy
        discovery_title
        discovery_copy
        buyers_copy
        search_title
        search_copy
        selling_title
        selling_copy
        casual_sellers_copy
        profile_title
        profile_copy
        blueprint_title
        blueprint_copy
        expertise
      }
      images {
        hero_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        grid_1_value_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_buyer_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_item_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        grid_1_ebay_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        browse_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_framework_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_mobile_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        search_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        selling_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_mobile_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_framework_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        profile_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        sizzle_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        
      }
      videos {
        hero {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_value {
          src
        }
        grid_1_buyer {
          src
        }
        browse {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_2_mobile {
          src
        }
        search {
          src {
            src_large
            src_medium
            src_small
          }
        }
        selling {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_3_mobile {
          src
        }
        profile {
          src {
            src_large
            src_medium
            src_small
          }
        }
        sizzle {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`
